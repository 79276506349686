window.axeptioSettings = {
  clientId: process.env.AXEPTIO_PROJECT_ID,
  cookiesVersion: process.env.AXEPTIO_COOKIE_VERSION,
  googleConsentMode: {
    default: {
      analytics_storage: 'denied',
      ad_storage: 'denied',
      ad_user_data: 'denied',
      ad_personalization: 'denied',
      wait_for_update: 500,
    },
  },
};

(function (d, s) {
  var t = d.getElementsByTagName(s)[0], e = d.createElement(s);
  e.async = true;
  e.src = '//static.axept.io/sdk.js';
  t.parentNode.insertBefore(e, t);
})(document, 'script');